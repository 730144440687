import { useEffect, useState, useRef, useMemo, useContext } from "react";
import { t, Trans } from "@lingui/macro"

import { Layout, Tag, Col, Row, Avatar, Button, Skeleton, Flex, Input } from "antd";
import { Spinner, Tooltip, CardFooter } from "reactstrap";

import { useHistory } from 'react-router';
import { sortBy, debounce } from 'lodash';

import dayjs from "dayjs";
import "dayjs/locale/en";

import { filterFormPropertySchema, Color } from './util'
import { FilterForm } from "../FormComponent";

import UWEEntityApi from "../../api/UWEEntityApi";
import useSearchParams from "../../util/useSearchParams";
import Pagination from "../Pagination";
import useResourceLoader from "../../util/useResourceLoader";

import leftPad from "../../util/leftPad";
import SearchBar from "../Search/SearchBar";

import { AuthContext } from "../../context/AuthContext";
import parseFormDefinition from "../../util/parseFormDefinition";
 
const getShiftInstanceId = (instance) => {
  const id = instance.id;
  const shiftDate = instance.metadata.shiftDate;
  const year = dayjs(shiftDate).format('YY');
  return `${year}-${id}`;
}

function ShiftViewer({ element }) {
  const ref = useRef();
  // Get props
  const {
    entityType,
    startDate,
    endDate,
    status,
  } = element;

  // Routing
  const history = useHistory();

  const auth = useContext(AuthContext);

  const formContext = { auth };

  const [formDefinition, sFilters] = parseSearchFormDefinition(element);

  // Get hashmap from status to color:
  // Jammy: Esto no es necesario solo se tiene que validar si status esta vacio o no al momento de usarlo.
  //        basicamente estas cogiendo status y lo estas pasando a un objeto nuevo y devolviendo el objeto
  const statusColors = useMemo(() => {
    const map = {};
    status.forEach(({name, color}) => {
      map[name] = color;
    });
    return map;
  }, [status]);

  // Get default start and end dates
  const defaultStartDate =
    startDate ||
    dayjs()
      .format("YYYY-MM-DD");
  const defaultEndDate =
    endDate ||
    dayjs()
      .add(1, "week")
      .format("YYYY-MM-DD");

  // Get entity name
  const entityName = useMemo(() => {
    return Array.isArray(entityType)? entityType.join(","): entityType;
  }, [entityType]);

  // ============================
  // = Filtering & Pagination
  // ============================

  // Shifts (no pagination yet)
  // const size = 2500; // Set more sensible default later...
  // const offset = 0;
  const [shifts, setShifts] = useState([]);

  const [
    [offset, setOffset],
    [size, setSize],
    [sortBy, setSortBy]
  ] = useSearchParams({
    offset: 0,
    size: 50,
    sortBy: element.defaultFilter
  });

  const [isLoadingShifts, setIsLoadingShifts] = useState(false);

  // Get the starting values for the filters
  const [filterValues, setFilterValues] = useState({
    "metadata-date-greaterthanorequal-shiftDate": defaultStartDate,
    "metadata-date-lessthanorequal-shiftDate": defaultEndDate
  });

  // --------------------
  // - Fetch shift data
  // --------------------

  const addFiltersToPayload = (payload) => {
    if (!filterValues) {
      return;
    }
    Object.entries(filterValues).forEach(([key, value]) => {
      payload[key] = value;
    });
  };

  // Fetch the shift data from UWE
  const fetchShiftData = async () => {
    if (!filterValues) {
      return [];
    }
    const resource = entityName
      ? `uwe-entities/${entityName}/list`
      : null;
    if (!resource) {
      return [];
    }

    const payload = {
      resource,
      offset,
      size,
      sortBy: element.defaultFilter,
    };

    addFiltersToPayload(payload);

    const data = await UWEEntityApi.getWorkTray(payload);
    return data;
  };


  function mergeParamsIntoFilters(filters, params) {
    if (!params || typeof params !== "object") {
      return filters;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        filters[key] = value
          .filter(Boolean)
          .map(String)
          .join(",");
      } else if (value != null && typeof value !== "object") {
        filters[key] = String(value);
      }
    });

    return filters;
  }


  const [paging, loading, error] = useResourceLoader(async () => {
    const resource = entityName
      ? `uwe-entities/${entityName}/list`
      : null;
    if (!resource) {
      return [];
    }

    const payload = {
      resource,
      offset,
      size,
      sortBy: element.defaultFilter,
    };

    addFiltersToPayload(payload);
    
    const data = await UWEEntityApi.getWorkTray(payload);
    setShifts(data.items);

    setIsLoadingShifts(false);
    return Array.isArray(data)
      ? {
          from: 0,
          to: data.length,
          count: data.length,
          items: data
        }
      : data;
  }, [size, offset, sortBy, element, filterValues]);


  const { from = 0, to = 0, count = 0, items: data = [] } = paging || {};

  // useEffect(() => {
  //   const bootstrapAsync = async () => {
  //     setIsLoadingShifts(true);

  //     const { from, to, count, items } = await fetchShiftData();
  //     setShifts(items);

  //     setIsLoadingShifts(false);
  //   };

  //   // Only show shifts once all filterValues are obtained
  //     bootstrapAsync();
  // }, [offset, filterValues]);
  
  // --------------------------
  // - Renders the page
  // --------------------------

  const [searchTerm, setSearchTerm] = useState('');
  const [currentStatus, setCurrentStatus] = useState(null);

  const onSearch = debounce(v => setSearchTerm(v.toLowerCase()), 350, { trailing: true })

  const renderShiftAvatars = (shiftData) => {
    const { availableSlots, shiftAllocationsUserNames } = shiftData.metadata;
    const id = shiftData.id;

    const users = shiftAllocationsUserNames ? JSON.parse(shiftAllocationsUserNames) : [];
    const availableUserSlots = Math.max(parseInt(availableSlots, 10) || 0, 0);

    const avatars = [];
    for (let i = 0; i < availableUserSlots; i += 1) {
      if (i < users.length) {
        const name = users[i] || "";
        const initials = name.split(' ').map((t) => t[0]).join('').toUpperCase();
        const avatarId = `avatar-${id}-${i}`;
        avatars.push(
          <>
            <Avatar key={i} size="large" id={avatarId}>{initials}</Avatar>
            <Tooltipped placement="top" target={avatarId}>{name}</Tooltipped>
          </>
        );
      }
      else {
        // Placeholder Avatar for unassigned slot
        avatars.push( 
          <Skeleton.Avatar 
            key={i}
            shape="circle"
            size="large" 
            style={{
              borderStyle: "dashed",
              borderColor: "#D3D3D3",
              borderWidth: "1px",
            }}
          />
        );
      }
    }

    return (
      <Avatar.Group
        size="large"
        max={{
          count: 6
        }}
      >
        {avatars}
      </Avatar.Group>
    );
  };

  const renderShiftCard = (shiftData) => {
    const { jobTypeTitle, locationName, shiftDate, startTime, endTime, jobStatus, name } = shiftData.metadata;
    const id = shiftData.id;

    const formattedStartTime = dayjs(`${shiftDate} ${startTime}`).format('h:mm a');
    const formattedEndTime = dayjs(`${shiftDate} ${endTime}`).format('h:mm a');
    const formattedShiftDate = dayjs(shiftDate).format('dddd MMMM D, YYYY')

    const shiftCardId = getShiftInstanceId(shiftData);

    return (
      <button
        onClick={() => history.push(`/workflow/shift-instance/${id}`)}
        style={{
          background: "transparent",
          border: "none",
          outline: "none",
          display: "block",
          padding: 0,
          width: "100%",
          cursor: "pointer",
          marginTop: 2,
          marginBottom: 15
        }}
      >
        <div
          key={id}
          className="shift-viewer-card"
          style={{borderRadius: 8, padding: 12}}
        >
          <Row>
            <Col xs={8}>
              <Flex
                style={{height: "100%"}}
                vertical
                align="flex-start"
                justify="space-between"
              >
                <span><b>{name}</b></span>
                <span>{locationName}</span>
                <span><em>{shiftCardId}</em></span>
              </Flex>
            </Col>
            <Col xs={8}>
              <Flex
                style={{height: "100%"}}
                vertical
                alignItems="center"
              >
                <div>{formattedShiftDate}</div>
                <p>{formattedStartTime} - {formattedEndTime}</p>
                <div>{jobTypeTitle}</div>
              </Flex>
            </Col>
            <Col xs={8}>
              <Flex
                style={{height: "100%"}}
                vertical
                align="flex-end"
                justify="space-between"
              >
                <Tag 
                  style={{ 
                    fontSize: 16,
                    color: Color.getContrastTextColor(statusColors[jobStatus]),
                    padding: 6,
                    marginBottom: 8,
                    marginRight: 0,
                  }}
                  color={statusColors[jobStatus]}
                >
                  {jobStatus}
                </Tag>
                {renderShiftAvatars(shiftData)}
              </Flex>
            </Col>
          </Row>
        </div>
      </button>
    )
  };

  const renderShiftCards = () => {
    if(!shifts){
      return <p>No shifts found.</p>;
    }

    let allShifts = shifts?.sort((s1, s2) => {
      const date1 = dayjs(s1.metadata.shiftDate);
      const date2 = dayjs(s2.metadata.shiftDate);
      if (date1.isBefore(date2)) return -1;
      else if (date1.isAfter(date2)) return 1;
      else return 0;
    });
    if (searchTerm) {
      allShifts = allShifts?.filter((sh) => {
        let doesMatch = false;
        if (sh.metadata.name) {
          doesMatch |= sh.metadata.name.toLowerCase().includes(searchTerm);
        }
        if (sh.metadata.locationName) {
          doesMatch |= sh.metadata.locationName.toLowerCase().includes(searchTerm);
        }
        if (sh.metadata.shiftDate) {
          const shiftCardId = getShiftInstanceId(sh);
          doesMatch |= shiftCardId.includes(searchTerm);          
        }
        return doesMatch;
      });
    }

    if(allShifts.length === 0){
      return <p>No shifts found.</p>;
    }

    return allShifts?.map(renderShiftCard);
  };

  const handleSearch = update => {
    setFilterValues({
      ...filterValues,
      ...update.formData
    });
  };

  return (
    <Flex className="worktray" vertical justify="flex-start">
      <h2 className={`title ${element.tableActions ? 'has-buttons' : ''}`}>
        All Shifts
      </h2>
      <FilterForm
        className="worktray-filters"
        tagName="div"
        ref={ref}
        formData={filterValues}
        formContext={{...formContext, ...filterValues}}
        {...formDefinition}
        onChange={handleSearch}
      />
      <Row style={{ marginTop: 16, flexGrow: 1, minHeight: 0 }}>
        <Col 
          span={4}
          style={{
            borderStyle: 'solid',
            borderColor: '#D3D3D3',
            borderWidth: "0px 1px 0px 0px",
          }}
        >
          <Flex 
            vertical
            align="flex-start"
            justify="center"
            style={{
              borderRight: 10,
              borderRightColor: 'black'
            }}
          >
            <h4><Trans>Status</Trans></h4>
            {
              status.map(({name, color}) => (
                <Button
                  key={name}
                  style={{
                    width: "13vw", 
                    minWidth: "50px",
                    height: "60px",
                    textAlign: 'flex-start',
                    justify: 'flex-start',
                    whiteSpace: 'normal',

                    color: Color.getContrastTextColor(color),
                    backgroundColor: color, 
                    borderColor: name === currentStatus ? Color.getContrastTextColor(color) : null,
    
                    // To customize antd card color
                    margin: "4px",
                    borderRadius: "12px",
                  }}
                  onClick={() => {
                    const val = name === currentStatus ? null : name;
                    setCurrentStatus(val);
                    setFilterValues({...filterValues, 'metadata-like-jobStatus': val})
                  }}
                > 
                    {name}
                </Button>
              ))
            }
          </Flex>
        </Col>
        <Col 
          span={20}
          style={{ paddingLeft: '2vw', height: '100%' }}
        >
          <Flex style={{ flexDirection: "column", height: "100%" }}>
            <Flex align="center" gap={8}>
              <h4><Trans>Shifts</Trans></h4>
              {isLoadingShifts && <Spinner size="sm" style={{ marginBottom: 8 }} />}
            </Flex>
            <Flex>
              {/* <SearchBar
                placeholder={t`Search`}
                onChange={onSearch}
              /> */}
              {
                currentStatus &&
                <Tag 
                  style={{ 
                    top: 4,
                    left: 10,
                    height: 23,
                    color: Color.getContrastTextColor(statusColors[currentStatus])

                  }}
                  color={statusColors[currentStatus]}
                  closable
                  closeIcon={<i className="fa fa-times-circle" aria-hidden="true"/>}
                  onClose={() => {
                    setFilterValues({...filterValues, 'metadata-like-jobStatus': null})
                    setCurrentStatus(null)
                  }}
                >
                  {currentStatus}
                </Tag>
              }
            </Flex>
            <div style={{ height: '100%', overflowY: 'scroll', marginRight: 10 }}>
              {renderShiftCards()}
            </div>
          </Flex>
          <CardFooter className="pagination">
            <Pagination
              offset={from}
              count={count}
              size={size}
              setSize={setSize}
              setOffset={setOffset}
            />
          </CardFooter>
        </Col>
      </Row>
      
      
    </Flex>
  );
}

function Tooltipped(props) {
  const [hover, setHover] = useState(false);
  
  return <Tooltip
    isOpen={hover}
    toggle={() => setHover(!hover)}
    {...props}
  />
}

ShiftViewer.rootSchema = {
  definitions: {
    mapFieldField: {
      type: ["string", "object"],
      "ui:field": "JsonField",
      showTree: true,
    },
  },
  properties: {
    entityType: {
      title: "Entity Type",
      type: "string",
      "map:field": "entityType",
      "ui:field": "LookupFormField",
      lookup: {
        resource: "Entity Types",
        api: "api:manage/objectschemas",
        params: { all: true },
        options: { useCache: true },
        jnx: "data.items",
        id: "name",
        label: "name",
      },
    },
    startDate: {
      title: "Start Date",
      type: "string",
      format: "date",
      structure: "Text",
      "map:field": "startDate",
    },
    endDate: {
      title: "End Date",
      type: "string",
      format: "date",
      structure: "Text",
      "map:field": "endDate",
    },
    defaultFilter: {
      title: "Default Filter",
      type: "string",
      "map:field": "defaultFilter"
    },

    status: {
      title: "Statuses",
      ":classNames": "from-col-1-size-2",
      type: "array",
      "map:array": "status",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            title: "Name",
            "map:field": "name",
          },
          color: {
            type: "string",
            title: "Color",
            "map:field": "color",
            structure: "Text",
            "ui:field": "ColorPicker",
          },
        },
      },
    },
    ...filterFormPropertySchema,
  },
};

const parseSearchFormDefinition = ({ filters }) => {
  if (filters) {
    const sFilters = {};
    const schema = filters.reduce((_, { key, ...obj }) => {
      _[key] = obj;
      return _;
    }, {});
    return [
      parseFormDefinition({
        schemaProps: {
          // ":classNames": "smallThreeRows",
        },
        schema
      }),
      sFilters
    ];
  }
  return [];
};

export default ShiftViewer;
