import { t, Trans } from "@lingui/macro";
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef
} from "react";
import {
  Table,
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  CardText,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonGroup
} from "reactstrap";
import { Flex } from "antd";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";

import useAccessCheck, { checkAccess } from "../../util/useAccessCheck";
import useResourceLoader from "../../util/useResourceLoader";
import UWEEntityApi from "../../api/UWEEntityApi";
import UweApi from "../../api/UweApi";
import Loader from "../Loader";
import Notification from "../Notification";
import useTitle from "../../util/useTitle";
import Pagination from "../Pagination";
import useSearchParams from "../../util/useSearchParams";
import SearchBar from "../Search/SearchBar";
import SortButton from "../SortButton";
import { AuthContext } from "../../context/AuthContext";
import { getObject, interpolate, mapObject } from "../../util/mapObject";
import WorkTrayIcon from "./WorkTrayIcon";
import parseFormDefinition from "../../util/parseFormDefinition";
import ElementIcon from "../ElementIcon";
import { useRouteInterpolation } from "../../util/routeUtil";
import { IDENTITY_FORMATTER, useFormatter } from "../../util/applyFormatting";
import Jnx, { useJnx } from "../../util/jnx";
import Diagram from "./Diagram";
import useLoader from "../../util/useLoader";
import { FilterForm } from "../FormComponent";

function WorkTray(props) {
  const ref = useRef();
  const { element, scope: propScope } = props;
  const [lastReload, setLastReload] = useState();
  const scope = useMemo(
    () => ({
      ...propScope,
      ...element?.scope,
      lastReload,
      reload: () => setLastReload(new Date().getTime())
    }),
    [propScope, lastReload, setLastReload, element]
  );

  const history = useHistory();

  useTitle(element.title);

  const auth = useContext(AuthContext);
  const { user: { user: { role: { extras } = {} } = {} } = {} } = auth;

  const defaultView = useMemo(
    () => (extras ? JSON.parse(extras).defaultView : null),
    [extras]
  );

  // Pass in the auth context as formContext for created Filter Form
  const formContext = { auth };

  const [formDefinition, sFilters] = parseSearchFormDefinition(element);
  const [filters, setFilters] = useState(sFilters);

  useEffect(() => setFilters({ ...(filters || {}), ...(defaultView || {}) }), [
    defaultView
  ]);

  const [
    [offset, setOffset],
    [size, setSize],
    [sortBy, setSortBy]
  ] = useSearchParams({
    offset: 0,
    size: 10,
    sortBy: element.defaultFilter
  });

  const { lookup, isSelected, setSelected } = useWorktrayHooks(props);

  function mergeParamsIntoFilters(filters, params) {
    if (!params || typeof params !== "object") {
      return filters;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        filters[key] = value
          .filter(Boolean)
          .map(String)
          .join(",");
      } else if (value != null && typeof value !== "object") {
        filters[key] = String(value);
      }
    });

    return filters;
  }

  const [paging, loading, error] = useResourceLoader(async () => {
    const entity = Array.isArray(lookup.entity)
      ? lookup.entity.join(",")
      : lookup.entity;
    const resource = entity
      ? `uwe-entities/${encodeURIComponent(entity)}/list`
      : lookup?.api?.split(":")?.[1];
    if (!resource) return null;
    mergeParamsIntoFilters(filters, lookup?.params);
    const data = await UWEEntityApi.getWorkTray({
      resource,
      offset,
      size,
      sortBy,
      ...filters
    });
    return Array.isArray(data)
      ? {
          from: 0,
          to: data.length,
          count: data.length,
          items: data
        }
      : data;
  }, [size, offset, sortBy, filters, element, lookup, lastReload]);

  const { from = 0, to = 0, count = 0, items: data = [] } = paging || {};

  const onSearch = update => {
    setFilters({
      ...filters,
      ...update.formData
    });
  };

  const [tab, setTab] = useState("table");

  return (
    <Flex
      className={`worktray ${element[":classNames"]}`}
      onClick={e => {
        e.preventDefault();
      }}
      role="table"
      style={{ flexDirection: "column" }}
    >
      {element.canCreate ? <WorkTrayIcon element={element.canCreate} /> : null}

      <div className="worktray-header">
        <div className={`title ${element.tableActions ? "has-buttons" : ""}`}>
          <h2>{element.title}</h2>
        </div>

        {element.tableActions ? (
          <ActionButtonsCell actions={element.tableActions} scope={scope} />
        ) : null}

        {element.canSeeDiagram ? (
          <div className="float-right">
            {tab !== "tree" ? (
              <Button color="secondary" onClick={() => setTab("tree")}>
                <i className="fa fa-network-wired" />
                Ver Diagrama
              </Button>
            ) : (
              <Button color="secondary" onClick={() => setTab("table")}>
                <i className="fa fa-table" /> Ver Tabla
              </Button>
            )}
          </div>
        ) : null}
      </div>
      {formDefinition != null && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 8,
            marginBottom: 16,
            marginTop: 16
          }}
        >
          <small style={{ color: "gray", fontSize: "10pt" }}>
            <Trans>Filters:</Trans>
          </small>
          <FilterForm
            className="worktray-filters"
            tagName="div"
            ref={ref}
            formData={filters}
            formContext={{...formContext, ...filters}}
            {...formDefinition}
            onChange={onSearch}
          />
        </div>
      )}
      <Card className="inbox-card worktray-content" style={{ flexGrow: 1, minHeight: 0 }}>
        {element.canSeeDiagram && tab === "tree" ? (
          <Diagram
            data={data}
            dataDiagram={element.canSeeDiagramnt}
            history={history}
          />
        ) : (
          <Flex style={{ flexDirection: "column", height: "100%" }}>
            <div style={{ overflowY: "scroll", flexGrow: 1, minHeight: 0 }}>
              <Table className="hide-when-mobile">
                <thead>
                  <tr>
                    {element.columns.map(
                      ({ sortkey, title, style, headerStyle }, idx) =>
                        sortkey ? (
                          <SortButton
                            key={idx}
                            tag="th"
                            sortKey={sortkey}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                            style={style || headerStyle}
                          >
                            {title}
                          </SortButton>
                        ) : (
                          <th key={idx} style={style || headerStyle}>
                            {title}
                          </th>
                        )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="8" style={{ paddingTop: 100 }}>
                        <Loader centered>
                          <Trans>Loading {element.title}</Trans>
                        </Loader>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {error ? (
                        <tr>
                          <td colSpan="8">
                            <Notification error={error} />
                          </td>
                        </tr>
                      ) : null}
                      {data && data.length ? (
                        data.map((rowObject, idx) => (
                          <DataRow
                            key={idx}
                            columns={element.columns}
                            rowObject={rowObject}
                            scope={scope}
                            selected={isSelected(rowObject)}
                            setSelected={setSelected}
                          />
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7">
                            <Notification color="warning">
                              <Trans>The list of {element.title} is empty.</Trans>
                            </Notification>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="mobile-table show-when-mobile">
              {loading ? (
                <Loader centered>
                  <Trans>Loading {element.title}</Trans>
                </Loader>
              ) : (
                <>
                  {data && data.length ? (
                    data.map((rowObject, idx) => (
                      <MobileDataRow
                        key={idx}
                        columns={element.columns}
                        rowObject={rowObject}
                        scope={scope}
                        selected={isSelected(rowObject)}
                        setSelected={setSelected}
                      />
                    ))
                  ) : (
                    <Notification color="warning">
                      <Trans>The list of {element.title} is empty.</Trans>
                    </Notification>
                  )}
                </>
              )}
            </div>
            {!loading && (
              <CardFooter className="pagination">
                <Pagination
                  offset={from}
                  count={count}
                  size={size}
                  setSize={setSize}
                  setOffset={setOffset}
                />
              </CardFooter>
            )}
          </Flex>
        )}
      </Card>
    </Flex>
  );
}

function useWorktrayHooks({
  element,
  selected: propSelected,
  setSelected: propSetSelected
}) {
  const { selectable } = element;
  const auth = useContext(AuthContext);

  const _lookup = useMemo(() => {
    let lookup = null;
    if (element.lookup) {
      lookup = { ...element.lookup };
    } else {
      let entityType;
      if (element.useMainRoleEntities) {
        const userRoles = auth?.user?.user?.roles || [];
        const mainRole = userRoles.find(x => x.mainRole) ?? userRoles[0];
        entityType = Object.entries(mainRole?.role?.entities ?? {})
          .map(([key, { homeTray }]) => (homeTray ? key : null))
          .filter(x => !!x);
      } else {
        entityType = element.entityType;
        if (element.otherEntityTypes?.length) {
          entityType = [entityType, ...element.otherEntityTypes].filter(
            x => !!x
          );
        }
      }
      lookup = {
        entity: entityType,
        api:
          !element.entityType && element.resource
            ? `api:${element.resource}`
            : null,
        params: element.lookupParams
      };
    }

    const paramsJnx = {};
    if (lookup?.params) {
      Object.entries(lookup.params).forEach(([k, v]) => {
        if (k.startsWith("expr:")) {
          delete lookup.params[k];
          k = k.replace(/^expr:/, "");
          paramsJnx[k] = new Jnx(v);
        }
      });
    }

    return { ...lookup, paramsJnx };
  }, [element?.lookup, element, auth]);
  const idAttr = _lookup.id;

  const bindings = useMemo(
    () => ({
      auth,
      idAttr
    }),
    [auth, idAttr]
  );

  const lookup = useMemo(
    () => ({
      ..._lookup,
      params: {
        ..._lookup.params,
        ...Object.entries(_lookup.paramsJnx).reduce((_, [k, jnx]) => {
          _[k] = jnx.eval({}, "", bindings);
          return _;
        }, {})
      }
    }),
    [_lookup]
  );

  const { isSelected, setSelected } = useMemo(() => {
    let isSelected = () => false;
    let setSelected;

    if (selectable) {
      const valueFn = idAttr ? x => x[idAttr] : x => x;
      isSelected = x => {
        const xValue = valueFn(x);
        return xValue === propSelected;
      };
      setSelected = x => propSetSelected(valueFn(x));
    }

    return { isSelected, setSelected };
  }, [selectable, idAttr, propSelected]);

  return {
    lookup,
    isSelected,
    setSelected
  };
}

WorkTray.rootSchema = {
  definitions: {
    mapFieldField: {
      type: ["string", "object"],
      "ui:field": "JsonField",
      showTree: true
    },
    requireRestrictfield: {
      type: "array",
      ":classNames": "from-col-1-size-2",
      "ui:arrayType": "cards",
      items: {
        type: "object",
        "ui:addButtonText": "Add Option",
        "ui:positionButtons": "top-outside",
        properties: {
          flag: {
            title: t`User Flag`,
            type: "string"
          },
          role: {
            title: t`Role`,
            type: "string",
            "ui:field": "LookupFormField",
            lookup: {
              api: "roles",
              resource: t`Roles`,
              id: "name",
              label: "name"
            }
          },
          permission: {
            title: t`Permission`,
            type: "string",
            enum: ["canWorkAs", "canBeAssigned", "canAssign"],
            enumNames: [t`Can Work`, t`Can be Assigned`, t`Can Assign`]
          }
        },
        "ui:field": "ToggleObjectField"
      }
    },
    actionsField: {
      type: "array",
      title: "Actions",
      "ui:arrayType": "cards",
      "ui:newRow": true,
      items: {
        type: "object",
        "ui:addButtonText": "Add Action",
        "ui:positionButtons": "top",
        "ui:sectionType": "subsubsection",
        properties: {
          obj: {
            type: "object",
            ":classNames": "grid-2-columns",
            title: ["# ${$index + 1}: ${label}"].join(""),
            "ui:newSection": true,
            "ui:expandable": true,
            properties: {
              label: {
                title: "Action",
                type: "string",
                "map:field": "label"
              },
              backgroundColor: {
                title: "Color",
                type: "string",
                "map:field": "backgroundColor"
              },
              route: {
                title: "Route",
                type: "string",
                "map:field": "route"
              },
              routeExpr: {
                title: "routeExpr",
                ":classNames": "from-col-1-size-1",
                type: ["string", "object"],
                "ui:field": "JsonField",
                "map:field": "routeExpr"
              },
              requireExpr: {
                title: "requireExpr",
                type: ["string", "object"],
                "ui:field": "JsonField",
                "map:field": "requireExpr"
              },
              requirerestrict: {
                type: "object",
                ":classNames": "from-col-1-size-2",
                title: "Conditions",
                "ui:sectionType": "label",
                "ui:field": "ToggleObjectField",
                properties: {
                  require: {
                    title: "Require Any",
                    "map:field": "require",
                    $$include: "#/definitions/requireRestrictfield"
                  },
                  restrict: {
                    title: "Restrict If Any",
                    "map:field": "restrict",
                    $$include: "#/definitions/requireRestrictfield"
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  properties: {
    title: {
      title: "Title",
      type: "string",
      "map:field": "title"
    },
    className: {
      title: "Class Names",
      type: "string",
      "map:field": ":classNames"
    },
    eT: {
      type: "object",
      title: " ",
      properties: {
        entityType: {
          title: "Entity Type",
          type: "string",
          "map:field": "entityType",
          "ui:field": "LookupFormField",
          lookup: {
            resource: "Entity Types",
            api: "api:manage/objectschemas",
            params: { all: true },
            options: { useCache: true },
            jnx: "data.items",
            id: "name",
            label: "name"
          }
        },
        otherEntityTypes: {
          title: " ",
          type: "array",
          "map:field": "otherEntityTypes",
          "ui:sectionType": "label",
          items: {
            "ui:field": "LookupFormField",
            title: " ",
            required: false,
            min: 0,
            "ui:orderable": false,
            type: "string",
            lookup: {
              resource: "Entity Types",
              api: "api:manage/objectschemas",
              params: { all: true },
              options: { useCache: true },
              jnx: "data.items",
              id: "name",
              label: "name"
            }
          }
        },
        useMainRoleEntities: {
          title: "Show all entity types from main role",
          type: "boolean",
          "map:field": "useMainRoleEntities"
        }
      }
    },
    resource: {
      title: "Resource",
      type: "string",
      "map:field": "resource"
    },
    lookupParams: {
      title: "Params",
      type: "object",
      "map:field": "lookupParams",
      properties: {},
      additionalProperties: {
        type: "string",
        title: "value"
      },
      "ui:asPropertiesTable": true
    },
    tableActions: {
      $$include: "#/definitions/actionsField",
      ":classNames": "from-col-1-size-3",
      "map:array": "tableActions"
    },
    defaultFilter: {
      title: "Default Filter",
      type: "string",
      "map:field": "defaultFilter"
    },
    filters: {
      title: "Filters",
      ":classNames": "from-col-1-size-3",
      type: ["string", "object"],
      "ui:field": "JsonField",
      "map:field": "filters"
    },
    columns: {
      title: "Columns",
      ":classNames": "from-col-1-size-3",
      type: "array",
      "map:array": "columns",
      items: {
        type: "object",
        "ui:showColumnsIf": {
          actions: {
            expr: "hasActions",
            debug: "actions showColumnsIf",
            scope: ".."
          }
        },
        properties: {
          title: {
            type: "string",
            "map:field": "title",
            title: "Title"
          },
          sortkey: {
            type: "string",
            "map:field": "sortkey",
            title: "Key"
          },
          mapField: {
            $$include: "#/definitions/mapFieldField",
            "map:field": "map:field",
            title: "Field"
          },
          format: {
            type: "string",
            "map:field": "format",
            title: "format"
          },
          panel2: {
            "ui:newRow": true,
            type: "object",
            title: "Extra Properties...",
            "ui:field": "HideObjectField",
            "ui:newSection": true,
            "ui:sectionType": "subsubsection",
            "ui:expandable": true,
            "ui:expandedDefault": false,
            ":classNames": "grid-2-columns bordered",
            properties: {
              color: {
                $$include: "#/definitions/mapFieldField",
                "map:field": "color",
                title: "Color"
              },
              valueTranform: {
                $$include: "#/definitions/mapFieldField",
                "map:field": "valueTranform",
                title: "Value Tranform Expression"
              },
              mapImage: {
                $$include: "#/definitions/mapFieldField",
                ":classNames": "from-col-1-size-1",
                "map:field": "map:image",
                title: "Image"
              },
              iconSize: {
                title: "Icon Size",
                "ui:showIf": {
                  scope: ".",
                  expr: "$isTruthy(mapImage)",
                  debug: "iconSize showIf"
                },
                type: "string",
                enum: ["1em", "2em", "4em", "6em", "8em", "10em"],
                "map:field": { default: "icons.size", path: "fontSize" }
              },
              type: {
                $$include: "#/definitions/mapFieldField",
                "map:field": "type",
                title: "Type"
              },
              actions: {
                $$include: "#/definitions/actionsField",
                ":classNames": "from-col-1-size-2",
                "map:array": "actions"
              }
            }
          }
        }
      }
    }
  }
};

function DataRow({
  rowObject,
  columns,
  scope,
  selected,
  setSelected,
  openModal,
  onSearch,
  loaderFnAction
}) {
  const fnBindings = {
    onSearch,
    openModal,
    loaderFnAction
  };

  return (
    <tr>
      {columns.map((cell, idx) => {
        const isAction = Array.isArray(cell.actions) && cell.actions.length > 0;
        return (
          <td key={idx} style={cell.style}>
            {isAction ? (
              <div className="worktray-action-btns">
                <ActionButtonsCell
                  actions={cell.actions}
                  rowObject={rowObject}
                  scope={scope}
                />
              </div>
            ) : (
              <DataCell
                cell={cell}
                rowObject={rowObject}
                selected={selected}
                setSelected={setSelected}
                scope={scope}
              />
            )}
          </td>
        );
      })}
    </tr>
  );
}

function MobileDataRow({ rowObject, columns, scope, selected, setSelected }) {
  return (
    <Card>
      <CardBody>
        {columns
          .filter(({ mobilePosition }) => mobilePosition > 0 || 1)
          .map((cell, idx) =>
            cell.actions ? (
              <ActionButtonsCell
                key={idx}
                actions={cell.actions}
                rowObject={rowObject}
                scope={scope}
              />
            ) : (
              <CardText key={idx}>
                <MobileDataCell
                  cell={cell}
                  rowObject={rowObject}
                  selected={selected}
                  setSelected={setSelected}
                />
              </CardText>
            )
          )}
      </CardBody>
    </Card>
  );
}

function MobileDataCell({ rowObject, cell, selected, setSelected }) {
  const {
    "map:field": mapField,
    "map:image": mapImage,
    iconSize,
    format,
    type,
    checkbox,
    color: colorExpr,
    title
  } = cell;
  const emptyText = cell.emptyText || (checkbox ? "" : "---");

  const colorJnx = useJnx(colorExpr);

  const value = useMemo(
    () => (mapField ? mapObject(rowObject, { value: mapField }).value : null),
    [rowObject, mapField]
  );
  const color = useMemo(() => colorJnx?.eval(rowObject, "", { value }), [
    value,
    colorJnx,
    rowObject
  ]);
  const formatter = useFormatter(format);
  const text = useMemo(
    () =>
      formatter !== IDENTITY_FORMATTER
        ? formatter.apply(value)
        : value !== null && value !== undefined
        ? `${value}`
        : "",
    [value, formatter]
  );
  const image = useMemo(
    () => (mapImage ? mapObject(rowObject, { value: mapImage }).value : null),
    [rowObject, mapImage]
  );

  const onClick = useCallback(
    e => {
      e.preventDefault();
      setSelected(rowObject);
    },
    [rowObject, setSelected]
  );

  const flag = mapField ? value : selected;

  return (
    <>
      {image ? <ElementIcon element={image} fontSize={iconSize} /> : null}
      {image ? " " : null}
      {checkbox ? (
        <button
          className={`icon-check-field btn ${
            flag ? "btn-success" : "btn-secondary"
          }`}
          color="secondary"
          onClick={onClick}
        >
          {flag ? <i className="fa fa-check" /> : null}
        </button>
      ) : null}
      {type === "badge" ? (
        <Badge color={color} style={{ fontSize: "100%" }} pill>
          {text || emptyText}
        </Badge>
      ) : (
        `${title}${title ? ":" : ""} ${text}` || emptyText || ""
      )}
    </>
  );
}

function DataCell({ rowObject, cell, selected, setSelected, scope }) {
  const { "map:field": mapField, valueTranform: valueTranformExpr } = cell;

  const valueTranformJnx = useJnx(valueTranformExpr);

  const value = useMemo(() => {
    let value = mapField
      ? mapObject(rowObject, { value: mapField }, undefined, scope).value
      : null;

    if (valueTranformJnx)
      value = valueTranformJnx.eval(rowObject, "", { value, scope });

    return Array.isArray(value) ? value : [value];
  }, [rowObject, mapField, valueTranformJnx, scope]);

  return value.map((item, index) => (
    <DataCellValue
      key={index}
      value={item}
      rowObject={rowObject}
      cell={cell}
      selected={selected}
      setSelected={setSelected}
      scope={scope}
    />
  ));
}

function DataCellValue({ value, rowObject, cell, selected, setSelected }) {
  const {
    "map:field": mapField,
    "map:image": mapImage,
    iconSize,
    format,
    type,
    checkbox,
    color: colorExpr
  } = cell;
  const emptyText = cell.emptyText || (checkbox ? "" : "---");

  const colorJnx = useJnx(colorExpr);

  const color = useMemo(() => colorJnx?.eval(rowObject, "", { value }), [
    value,
    colorJnx,
    rowObject
  ]);
  const formatter = useFormatter(format);
  const text = useMemo(
    () =>
      formatter !== IDENTITY_FORMATTER
        ? formatter.apply(value)
        : value !== null && value !== undefined
        ? `${value}`
        : "",
    [value, formatter]
  );
  const image = useMemo(
    () => (mapImage ? mapObject(rowObject, { value: mapImage }).value : null),
    [rowObject, mapImage]
  );

  const onClick = useCallback(
    e => {
      e.preventDefault();
      setSelected(rowObject);
    },
    [rowObject, setSelected]
  );

  const flag = mapField ? value : selected;
  let result = (
    <>
      {image ? <ElementIcon element={image} fontSize={iconSize} /> : null}
      {image ? " " : null}
      {checkbox ? (
        <button
          className={`icon-check-field btn ${
            flag ? "btn-success" : "btn-secondary"
          }`}
          color="secondary"
          onClick={onClick}
        >
          {flag ? <i className="fa fa-check" /> : null}
        </button>
      ) : null}
      {text || emptyText || ""}
    </>
  );

  switch (type) {
    case "button":
      result = <Button color={color}>{result}</Button>;
      break;
    case "badge":
      result = (
        <Badge color={color} style={{ fontSize: "100%" }} pill>
          {result}
        </Badge>
      );
      break;
    case "square-badge":
      result = (
        <Badge color={color} style={{ fontSize: "100%" }}>
          {result}
        </Badge>
      );
      break;
  }

  return <div>{result}</div>;
}

const BUTTONS_COMPONENTS = {
  confirmbutton: ConfirmButton,
  reload: ReloadButton
};

function ActionButtonsCell({ actions, rowObject, scope, fnBindings }) {
  const auth = useContext(AuthContext);

  const buttons = useMemo(
    () =>
      actions.map(obj => {
        const newObj = { ...obj };
        if (obj.requireExpr) {
          newObj.requireJnx = new Jnx(obj.requireExpr);
        }
        return newObj;
      }),
    [actions]
  );

  const filteredActions = useMemo(
    () =>
      buttons.filter(obj => {
        return (
          (!obj.requireJnx || obj.requireJnx.eval(rowObject, "", scope)) &&
          (!obj.require ||
            obj.require.some(requirement =>
              checkButtonAccess(requirement, rowObject, auth)
            )) &&
          (!obj.restrict ||
            obj.restrict.every(
              requirement => !checkButtonAccess(requirement, rowObject, auth)
            ))
        );
      }),
    [auth, rowObject, scope, buttons]
  );

  return (
    <>
      {filteredActions.map((action, idx) => {
        const Component =
          BUTTONS_COMPONENTS[action?.type?.toLowerCase()] || ActionButton;
        return (
          <Component
            fn={fnBindings}
            key={idx}
            action={action}
            rowObject={rowObject}
            scope={scope}
          />
        );
      })}
    </>
  );
}

function checkButtonAccess({ role, permission, flag }, rowObject, auth) {
  return (
    (role ? checkAccess(auth, role, permission) : true) &&
    (flag ? getObject(rowObject, flag) : true)
  );
}

function ActionButton({ action, rowObject, scope: propScope }) {
  const [loading, error, loadFn] = useLoader();
  const scope = useMemo(
    () => ({
      id: rowObject?.id,
      entityTypeSlug: rowObject?.entityTypeSlug,
      item: rowObject,
      ...propScope,
      loadFn
    }),
    [rowObject, propScope, loadFn]
  );

  const route = useRouteInterpolation(action.route, action.routeExpr, scope);
  const onClick = useMemo(() => {
    if (!action.onClick) return null;
    if (typeof action.onClick === "function") return action.onClick;
    const jnx = new Jnx(action.onClick);
    return (rowObject, scope) => jnx.eval(rowObject, "", { scope });
  }, [action.onClick]);

  const bgColor = action.backgroundColor || null;
  const borderColor = action.borderColor ?? bgColor;

  let dataCy = `btn_${action.label}`;
  if (scope?.id) dataCy += `_${scope.id}`;

  return loading ? (
    <Loader />
  ) : route ? (
    <Link
      style={{ backgroundColor: bgColor, borderColor }}
      // style={{ 'backgroundColor': bgColor, 'borderColor': borderColor }}
      className="btn btn-primary worktray-action-btn"
      data-cy={dataCy}
      to={route}
    >
      {action.label}
    </Link>
  ) : onClick ? (
    <Button
      style={{
        backgroundColor: action.backgroundColor ? action.backgroundColor : null
      }}
      color="primary"
      data-cy={dataCy}
      onClick={() => onClick(rowObject, scope)}
    >
      {action.label}
    </Button>
  ) : null;
}

async function onConfirm({ confirm, fn }) {
  const WORKFLOW_SELECT = () => {
    return ["ConfirmationModal", confirm?.title || ``, confirm?.text || ``];
  };

  const wf = await fn.openModal(...WORKFLOW_SELECT());
  if (wf) {
    fn.loaderFnAction(true);
    const response = await UweApi[confirm.method]({ api: confirm.api });
    if (response) fn.onSearch();
    fn.loaderFnAction(false);
  }
}

function ConfirmButton({ action, rowObject, scope: propScope, fn }) {
  const scope = useMemo(
    () => ({
      id: rowObject?.id,
      entityTypeSlug: rowObject?.entityTypeSlug,
      item: rowObject,
      ...propScope
    }),
    [rowObject, propScope]
  );

  const api = interpolate(action.confirm.api, scope);

  let dataCy = `btn_confirm_${action.label}`;
  if (scope?.id) dataCy += `_${scope.id}`;

  return (
    <Button
      className="btn btn-primary"
      color=""
      data-cy={dataCy}
      onClick={() => onConfirm({ fn, confirm: { ...action?.confirm, api } })}
    >
      {action.label}
    </Button>
  );
}

const DEFAULT_PERIODS = [1, 5, 15, 30, 60, 0];
function ReloadButton({ action, scope }) {
  const { period: defaultPeriod, periods: _periods } = action;
  const periods = _periods || DEFAULT_PERIODS;
  const [period, setPeriod] = useState(defaultPeriod);
  const paused = !period;
  const { lastReload, reload } = scope;
  const nextReload = useMemo(
    () => (lastReload ?? new Date().getTime()) + period * 1000,
    [period, lastReload]
  );
  const now = new Date().getTime();

  let dataCy = `btn_reload_${action.label}`;
  if (scope?.id) dataCy += `_${scope.id}`;

  useEffect(() => {
    if (paused) return;
    const timeLeft = nextReload - new Date().getTime();
    if (timeLeft < 0) {
      reload();
    } else {
      const timeout = setTimeout(() => {
        reload();
      }, Math.max(timeLeft, 10));
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [paused, nextReload]);

  return (
    <ButtonGroup>
      <Button
        className="btn btn-primary"
        color=""
        data-cy={dataCy}
        onClick={() => reload()}
      >
        {action.label || <i className="fa fa-redo" />}
      </Button>
      <UncontrolledDropdown className="user-nav-dropdown">
        <DropdownToggle className="btn btn-primary" color="" caret nav>
          {period ? `${period}s` : "Off"}
        </DropdownToggle>
        <DropdownMenu right>
          {periods.map((period, index) => (
            <DropdownItem key={index} onClick={() => setPeriod(period)}>
              {period ? `${period}s` : "Off"}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </ButtonGroup>
  );
}

const parseSearchFormDefinition = ({ filters }) => {
  if (filters) {
    const sFilters = {};
    const schema = filters.reduce((_, { key, ...obj }) => {
      _[key] = obj;
      return _;
    }, {});
    return [
      parseFormDefinition({
        schemaProps: {
          // ":classNames": "smallThreeRows",
        },
        schema
      }),
      sFilters
    ];
  }
  return [];
};

export default WorkTray;

/**
 * Temporary exports pending refactor.
 * @see {@link module:components/Dashboard/util/FilterForm}
 */
export { parseSearchFormDefinition };
