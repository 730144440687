import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import _ from "lodash";
import queryString from 'query-string';
import clone from "./clone";
import { getObject, invertMap, mapObject, setObject } from "./mapObject";
import sequenceFormatting from "./sequenceFormatting";
import leftPad from "./leftPad";

dayjs.extend(utc);
dayjs.extend(timezone);

function withSignature(signature, fn) {
    fn.signature = signature;
    return fn;
}

const DOMINICAN_TZ = 'America/Dominica'; // AST
const SLA_COLORS = ["success", "warning", "danger", ""];

const CHARSETS = {
    'w': 'abcdefghijklmnopqrstuvwxyz',
    'W': 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    '0': '0123456789',
    '!': '!@#$%^&*(),<.>;:[{]}\\/?|',
};

const TIME_UNITS = {
    'millis': 1,
    'seconds': 1000,
    'minutes': 60000,
    'hours': 3600000,
    'days': 86400000,
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

function formatDate(date, formatString) {
    const result = new Date(date);

    const formatMap = {
        YYYY: result.getFullYear(),
        MM: padTo2Digits(result.getMonth() + 1),
        DD: padTo2Digits(result.getDate()),
        hh: padTo2Digits(result.getHours() % 12 || 12),
        HH: padTo2Digits(result.getHours()),
        mm: padTo2Digits(result.getMinutes()),
        ss: padTo2Digits(result.getSeconds()),
        A: result.getHours() >= 12 ? 'PM' : 'AM',
        a: result.getHours() >= 12 ? 'pm' : 'am'
    };

    return formatString.replace(/YYYY|MM|DD|hh|HH|mm|ss|A|a/g, matched => formatMap[matched]);
}

const jsonataFunctions = {
    deepMerge(...objs) { return _.merge({}, ...objs); },
    coalesce(...values) {
        for (let i = 0; i < values.length; i += 1) {
            const x = values[i];
            if (x !== null && x !== undefined) return x;
        }
    },
    formatSequence(value, format) {
        return sequenceFormatting(value, format);
    },
    charSet: withSignature(
        '<s:a<s>>',
        (cstype) => cstype.split('').reduce((_, cstype) => {
            const CS = CHARSETS[cstype];
            if (CS && !_.sets[cstype]) _.list.push(...CS);
            return _;
        }, { list: [], sets: {} }).list
    ),
    fromCharCode: withSignature(
        '<n:s>',
        String.fromCharCode
    ),
    fromCodePoint: withSignature(
        '<n:s>',
        String.fromCodePoint
    ),
    charAt: withSignature(
        '<sn:s>',
        (str, index) => str.charAt(index)
    ),
    charCodeAt: withSignature(
        '<sn:n>',
        (str, index) => str.charCodeAt(index)
    ),
    codePointAt: withSignature(
        '<sn:n>',
        (str, index) => str.codePointAt(index)
    ),
    int: x => x | 0,
    float: x => +x,
    range: withSignature(
        '<nn?n?:a<n>>',
        (from, to = undefined, step = 1) => {
            if (to === undefined) {
                to = from;
                from = 0;
            }

            const arr = [];
            for (let i = from; i < to; i += step) arr.push(i);

            return arr;
        }
    ),
    copy: data => {
        navigator.clipboard.writeText(`${data}`);
    },
    log: (...args) => { console.log(...args); },
    alert: text => { alert(text); return text; },
    isJson: text => {
        try {
            JSON.parse(text);
            return true;
        } catch (e) {
            return false;
        }
    },

    mapObject,
    invertMap,
    getObject,
    setObject,

    pathUp(path, i = 0) {
        const components = path.split('.');
        return components.slice(0, Math.max(0, components.length - i - 1)).join('.');
    },

    pathJoin(...paths) {
        return paths.reduce((_, p) => {
            if (!p) return _;
            if (p.startsWith('.')) p = p.substr(1);
            if (!p) return _;
            if (p.endsWith('.')) p = p.substr(0, p.length - 1);
            p.split('.').forEach(component => {
                if (component === '') {
                    _.pop();
                } else {
                    _.push(component);
                }
            });
            return _;
        }, []).join('.');
    },


    first: array => array ? array[0] : null,
    last: array => array ? array[array.length - 1] : null,

    isTruthy: value => !!value,
    isFalsy: value => !value,
    isEmpty: value => {
        switch (typeof value) {
            case 'string': return value.trim() === '';
            case 'object': return !value || !(Object.keys(value).length);
            case 'undefined': return true;
            default: return false;
        }
    },
    toJson: object => JSON.stringify(object),
    fromJson: text => JSON.parse(text),
    wait: milliseconds => new Promise(resolve => {
        setTimeout(() => resolve(), milliseconds);
    }),
    "if": (condition, thenFn, elseFn) => {
        if (condition) {
            return thenFn ? thenFn() : undefined;
        } else {
            return elseFn ? elseFn() : undefined;
        }
    },
    throw: message => { throw new Error(message); },
    try: (action, onError, onSuccess) => {
        let p = Promise.resolve().then(action);
        if (onSuccess) p = p.then(onSuccess);
        return p.catch(onError);
    },
    random: withSignature('<:n>', Math.random),
    sample: withSignature(
        '<a:x>',
        (arr) => {
            const ridx = ((Math.random() * arr.length) | 0) % arr.length;
            return arr[ridx];
        }
    ),
    fetch: fetch.bind(window),
    newDate: (new Date()),
    millisAdjust: (millis, unit, value) => (millis ?? new Date().getTime()) - value * (TIME_UNITS[unit] || 1),
    millisTZAdjust: (millis, hours) => (millis ?? new Date().getTime()) - hours * TIME_UNITS.hours,
    millisFloor: (millis, unit) => {
        const scale = TIME_UNITS[unit] || 1;
        return Math.floor((millis ?? new Date().getTime()) / scale) * scale;
    },
    getBusinessDatesCount: (startDate, endDate) => {
        startDate = new Date(jsonataFunctions.toDrDateTime(startDate).format("YYYY/MM/DD h:mm A"));
        endDate = new Date(jsonataFunctions.toDrDateTime(endDate).format("YYYY/MM/DD h:mm A"));
        let count = 0;
        let curDate = parseInt(+startDate / 86400000, 10) * 86400000;
        while (curDate < parseInt(+endDate / 86400000, 10) * 86400000) {
            const dayOfWeek = new Date(curDate).getDay();
            const isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
            if (!isWeekend) {
                count += 1;
            }
            curDate = curDate + 24 * 60 * 60 * 1000
        }
        return count;
    },
    toLocalDateTime: datetime => {
        if (!datetime) {
            return null
        }
        if (typeof datetime === 'string') datetime = datetime.replace('Z', '');

        const inUTC = dayjs(datetime).utc();

        const localDateTime = inUTC.local();

        return localDateTime.format('MM/DD/YYYY HH:mm');
    },
    toDrDateTime: datetime => {
        // This is to make all datetime fields consistent, some come with a Timezone.
        if (typeof datetime === 'string') datetime = datetime.replace('Z', '');
        const inUTC = dayjs(datetime).tz("UCT", true);
        return inUTC.tz(DOMINICAN_TZ);
    },
    makeUrl: (path, params) => {
        if (params) {
            const prefix = path.indexOf('?') === -1 ? '?' : '&';
            path += prefix + queryString.stringify(params);
        }
        return path;
    },
    openWindow: (url, target, features) => {
        return window.open(url, target || "_blank", features);
    },
    onlyDate: datetime => {
        // This is to make all datetime fields consistent, some come with a Timezone.
        if (typeof datetime === 'string') datetime = datetime.replace('Z', '');
        const inUTC = dayjs(datetime).tz("UCT", true).format('DD/MM/YYYY');
        return inUTC;
    },
    differenceDays: (startDate, endDate) => {
        startDate = new Date(jsonataFunctions.toDrDateTime(startDate).format("YYYY/MM/DD h:mm A"));
        endDate = new Date(jsonataFunctions.toDrDateTime(endDate).format("YYYY/MM/DD h:mm A"));
        let count = 0;
        let curDate = parseInt(+startDate / 86400000, 10) * 86400000;
        while (curDate < parseInt(+endDate / 86400000, 10) * 86400000) {
            count += 1;
            curDate = curDate + 24 * 60 * 60 * 1000
        }
        return count;
    },
    addDuration(dateInput, duration) {
        const date = new Date(dateInput);
        // Add the duration in milliseconds
        date.setTime(date.getTime() + Number(duration));
        // Format the new time
        let newHours = date.getHours();
        const newMinutes = String(date.getMinutes()).padStart(2, '0');
        const newModifier = newHours >= 12 ? 'pm' : 'am';

        if (newHours > 12) {
            newHours -= 12;
        } else if (newHours === 0) {
            newHours = 12;
        }
        const newTimeString = `${String(newHours).padStart(2, '0')}:${newMinutes}${newModifier}`;
        return newTimeString;
    },
    formatTime12HrTo24Hr(timeStr) { // Ex. 12:00am to 00:00:00
        const timeRegex = /(\d{1,2}):(\d{2})([ap]m)/i;
        const matches = timeStr.match(timeRegex);

        if (!matches) {
            throw new Error("Invalid time format");
        }

        const [, hourStr, minuteStr, period] = matches;

        // Convert hour and minute to integers
        let hour = parseInt(hourStr, 10);
        const minute = parseInt(minuteStr, 10);

        // Convert the hour to 24-hour format
        if (period.toLowerCase() === "pm" && hour !== 12) {
            hour += 12;
        } else if (period.toLowerCase() === "am" && hour === 12) {
            hour = 0;
        }

        // Format hour and minute as two digits
        const hourStrPadded = String(hour).padStart(2, '0');
        const minuteStrPadded = String(minute).padStart(2, '0');

        // Return the formatted time
        return `${hourStrPadded}:${minuteStrPadded}:00`;
    },
    formatTime24HrTo12Hr(timeStr) { // Ex. 00:00:00 to 12:00am
        if(!timeStr) return null;

        let hours = parseInt(timeStr.substring(0, 2), 10);
        const minutes = parseInt(timeStr.substring(3, 5), 10);
        let period = 'am';

        if (hours >= 12) {
            period = 'pm';
            if (hours > 12) {
                hours -= 12;
            }
        } else if (hours === 0) {
            hours = 12;
        }

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}${period}`;
    },
    formatDateVerbose(dateStr) { // Ex. 2024-07-11 to Thursday, July 11, 2024
        if(!dateStr) return null;
        const [year, month, day] = dateStr.split('-').map(Number);
        const date = new Date(year, month - 1, day); // month is 0-based in JavaScript Date
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
        return formattedDate
    },
    validateShiftCompletion(array) { // Returns 'Completed' if all items in array have isCompleted = true, empty string otherwise.
        if(!array || !array?.length) return '';
        return !array.some(x => !x.isCompleted) ? 'Completed' : '';
    },
    getFormattedCurrDateTime() { // Returns current datetime in the following format: MM/DD/YYYY HH:mm
        const now = new Date();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
    
        return `${month}/${day}/${year} ${hours}:${minutes}`;
    },
    getShiftNumber(dateStr, shiftId) { // Ex. dateStr = '2024-07-11', shiftId = 12345, returns 24-12345
        const year = dayjs(dateStr).format('YY');
        return `${year}-${leftPad(shiftId, 5)}`;
    },
    slaColor: (lapsedTime, sla) => {
        sla = sla || [10, 20];
        if (lapsedTime < sla[0]) {
            return SLA_COLORS[0]
        }
        else if (lapsedTime < sla[1]) {
            return SLA_COLORS[1]
        }
        return SLA_COLORS[2];
    },
    compareDates(date1String, date2String) {
        const date1 = new Date(date1String);
        const date2 = new Date(date2String);

        if (!date1 || !date2) {
            return false;
        }

        if (date1 >= date2) {
            return 1;
        } else if (date1 < date2) {
            return 0;
        }
    },
    windowOpen: url => { window.open(url) },
    cloneSet: clone.set,
    addDays(date, days, format = 'YYYY-MM-DD') {
        let result = new Date(date);
        if (date === "$today") result = new Date();
        result.setDate(result.getDate() + days);
        if (format) {
            return formatDate(result, format);
        }
        return result;
    },
    getNextDayDate(dayName) {
        const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

        if (!daysOfWeek.includes(dayName.toLowerCase())) return;

        const targetDayIndex = daysOfWeek.indexOf(dayName.toLowerCase());

        const today = new Date();
        const currentDayIndex = today.getDay();

        let daysUntilTarget = targetDayIndex - currentDayIndex;
        if (daysUntilTarget <= 0) {
            daysUntilTarget += 7;
        }

        const nextDayDate = new Date(today);
        nextDayDate.setDate(today.getDate() + daysUntilTarget);

        return nextDayDate.toISOString().split('T')[0];
    },
    hasEndRepeat(repeat) {
        return repeat ? Object.values(repeat).map((o) => o).filter(x => x)?.length > 0 : false
    },
    pathUpWithIndex(path) {
        const lastDotIndex = path.lastIndexOf(".");
        return lastDotIndex !== -1 ? path.substring(0, lastDotIndex) : path;
    },
    findUserInAlloc(email, shiftAllocations) { // Validate if user's email is in shiftAllocations. If matches, returns current user's allocation status, else return Confirmed.
        if(email){
            let allocations = shiftAllocations
            if (typeof shiftAllocations === 'string'){
                allocations = JSON.parse(shiftAllocations)
            }
            let userWithMatchingEmail;
            if (typeof email === 'number'){
                userWithMatchingEmail = allocations.find(user => user.userId === email);
            }
            else{
                userWithMatchingEmail = allocations.find(user => user.userId.email === email);
            }
            if(userWithMatchingEmail){
                if(userWithMatchingEmail.clockIn && !userWithMatchingEmail.clockOut && !userWithMatchingEmail.adminClockOut){
                    return "In Progress"
                }
                else if(userWithMatchingEmail.clockIn && (userWithMatchingEmail.clockOut || userWithMatchingEmail.adminClockOut)){
                    return "Completed"
                }
                return userWithMatchingEmail.status;
            }
            else{
                return "Confirmed";
            }
        }
        else{
            return "Confirmed";
        }
    },
    async findUserCalendar(email, shiftData) {
        
        const shitDate = shiftData?.shiftDate || '';
        if(email && shiftData?.startTime && shiftData?.endTime && shitDate){
            const url = `https://windmar-backend.akcelita.com/api/getCalendarEventsUsingEmail?email=${email}&startDate=${shitDate}&endDate=${shitDate} 23:58:58&filter=''`;
            const response = await fetch(url);
            const data = await response.json();
            const newEvents = data.value.map((event, idx)=>{
                return {
                    id: idx+1,
                    title: event.subject,
                    start: new Date(event.start.dateTime.concat("", event.start.timeZone === 'UTC'?'Z':' GMT-0400')),
                    end: new Date(event.end.dateTime.concat("", event.end.timeZone === 'UTC'?'Z':' GMT-0400')),
                }
            })
            newEvents.forEach(event => {
                if (event.start){
        
                    const starting = event.start.toTimeString().substr(0,7)
                    const ending = event.end.toTimeString().substr(0,7)
                    console.log("shiftData.startTime", shiftData.startTime);
                    console.log("shiftData.endTime", shiftData.endTime);
                    console.log("calendar starting", starting);
                    console.log("calendar ending", ending);
                    if(!(shiftData.startTime < starting && shiftData.endTime <= starting || ending <= shiftData.startTime  && ending <= shiftData.endTime)){
                        alert(`The user ${email} have an event that conflict with this shift`);
                    }
                }   
                
            })
            
            return true;
        }
        else{
            return false;
        }
    },
    toLocalTime: datetime => {
        if (!datetime) {
            return null
        }
        if (typeof datetime === 'string') datetime = datetime.replace('Z', '');
        const localDateTime = dayjs(datetime).local();
        const timezoneOffset = new Date().getTimezoneOffset();
        const newTime = localDateTime.subtract(timezoneOffset, 'minute');
        return newTime.format('hh:mm a');
    },
};


export default jsonataFunctions;